import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import HackavizContest from '../../components/HackavizContest';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HackavizContest startDate={new Date('2025-03-03')} endDate={new Date('2025-03-17')} tallyFormUrl={'bit.ly/4jT0GnQ'} partyDate={new Date('2025-04-03')} extraMessage="Vous bénéficiez cette année d'une période prolongée de 4 jours supplémentaires !" customDataButtons={[{
      url: 'https://github.com/Toulouse-Dataviz/hackaviz-2025',
      buttonLabel: 'Lire la description'
    }, {
      url: 'https://github.com/Toulouse-Dataviz/hackaviz-2025/releases',
      buttonLabel: 'Télécharger les données'
    }]} mdxType="HackavizContest" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      